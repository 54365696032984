import React from "react";
import { Location } from "@reach/router";
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import MarTech from "../../components/MarTech";

function Projects({ data }) {
  const {
    featuredTui,
    featuredBWDC,
    featuredPCR,
    featuredNCCCO,
    featuredDM
  } = data

  const techImg = {
    AWS: "aws-logo.png",
    Amplify: "amplify-logo.png",
    React: "react-logo.svg",
    Python: "python-logo.svg",
    MongoDB: "mongodb-logo.jpg",
    PostgreSQL: "postgres-logo.png",
    HTML5: "html5-logo.svg",
    CSS3: "css3-logo.svg",
    JavaScript: "js-logo.svg",
    Figma: "figma-logo.svg",
    Stripe: "stripe2.png"
  };
  const projects = [
    {
      name: "Water Utility App",
      path: "water-utility-app",
      featuredImage: featuredTui,
      tech: ["Amplify", "React", "Python", "AWS", "Stripe"],
      Content: () => (
        <>
          <p>
            The{" "}
            <a
              className="text-primary underline"
              href="https://waterutilityapp.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Water Utility App
            </a>{" "}
            started as an idea by Tui Anderson, a Water Industry veteran and
            independent instructor for municipal operators. He’s worked as a
            water operator, source water protection specialist, and a trainer.
            Over the years in his work, he realized that there is a need for
            modern, simple, and easy to use tool for water companies.
            <br />
            <br />
            The main features of the app is to record and analyze water
            production and water consumption of a Water Utility company. A user
            can then generate a report that shows data graphs, loss analysis,
            calculated water losses, and recommendations to reduce losses.
            <br />
            <br />
            We decided to develop the app as a Progressive Web App (PWA). A PWA
            is a cross-platform app available for desktop, tablet, mobile
            (Android or iOS), and any web browser. The major benefit of choosing
            PWA is that you don't have to do separate development for each
            platform, so it saves time and money, but still get the same benefit
            from native apps.
            <br />
            <br />
            We used AWS Amplify and React.js. AWS is a collection of cloud
            computing services that's reliable, inexpensive, and scalable.
            Amplify is one of those services and it's a serverless framework for
            mobile apps and web apps. React is the wildly popular front-end
            JavaScript framework created by Facebook that's able to produce high
            performance user interfaces in any modern web browser
            (mobile/tablet/laptop/desktop).
            {/* <br /> */}
            {/* <br /> */}
            {/* To make our development with Amplify even better, we reinforced its */}
            {/* CRUDL solution. We developed our own custom solution to tunnel more */}
            {/* traditional RPC calls to our API through the existing Amplify */}
            {/* AppSync GraphQL endpoint. */}
          </p>
        </>
      )
    },
    {
      name: "Boise Works",
      path: "boise-works",
      featuredImage: featuredBWDC,
      tech: ["Amplify", "React", "Python", "AWS", "Figma"],
      Content: () => (
        <>
          <p>
            <a
              className="text-primary underline"
              href="https://boiseworks.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Boise Works
            </a>{" "}
            is an internal Gortek project. It's an app that connects local
            businesses, freelancers, and self-employed workers in the Treasure
            Valley.
            <br />
            <br />
            Originally launched as Boise Freelance, the app features business
            profile creation and listings. Part of the project is marketing on
            behalf of the business owners to attract users to the platform.
            Outreach and advertising has included Facebook, Instagram, LinkedIn,
            Twitter, and billboards. More channels are planned.
            <br />
            <br />
            The app is still being rebranded and will be announced under the new
            name in the coming weeks. (I guess this is a spoiler.)
          </p>
        </>
      )
    },
    {
      name: "PC Richard",
      path: "pc-richard",
      featuredImage: featuredPCR,
      tech: ["HTML5", "CSS3", "JavaScript"], //"Oracle e-Commerce" or Commerce Cloud,
      Content: () => (
        <>
          <a
            href="https://pcrichard.com"
            target="_blank"
            className="text-primary underline"
            rel="noopener noreferrer"
          >
            PC Richard
          </a>{" "}
          is a large, regional, family owned, appliance and electronics store in
          the North East United States.
          <br />
          <br />
          When revamping their e-commerce store, the dedicated IT contractors
          that PC Richard keeps on retainer brought in outside help to make
          their designs a reality.
          <br />
          <br />
          Talent scouts found Tony and hired him to develop the new version of
          their e-commerce product detail page (PDP), and category pages. Tony
          collaborated with the design staff to achieve pixel perfect renditions
          of their mockups.
          <br />
          <br />
          The categories and product description page you see on the live site
          now includes that work, along with the efforts of many other
          developers on their staff. Keeping the site beautiful and running
          smoothly is truly a team effort.
        </>
      )
    },
    {
      name: "NCCCO",
      path: "nccco",
      featuredImage: featuredNCCCO,
      tech: [],
      Content: () => (
        <>
          A mutual friend introduced Tony to Graham Brent, the then CEO of the{" "}
          <a
            href="https://nccco.org"
            target="_blank"
            className="text-primary underline"
            rel="noopener noreferrer"
          >
            National Commission for the Certification of Crane Operators (NCCCO)
          </a>
          .
          <br />
          <br />
          At the time, Graham's organization was coming to terms with the need
          for a major technology overhaul of their operational systems,
          day-to-day processes, internal databases, and customer facing systems.
          <br />
          <br />
          This was also right around the time NCCCO was celebrating its 20th
          Anniversary, reflecting on how far they had come as an organization,
          and was planning the foundation that would carry them "The Next 20
          Years".
          <br />
          <br />
          The NCCCO IT landscape had started with a paper based internal
          workflow that was augmented over the years with the addition of small
          MS Access databases, and a SQL Server based solution to host the
          question banks for their certification testing solution.
          <br />
          <br />
          The time had finally come to do away with dozens of filing cabinets of
          paper forms and routing slips. Fax machines would be retired. The
          hodge-podge of organically grown, human powered processes would be
          consolidated into a single, purpose built software system.
          <br />
          <br />
          Tony's task was to help the executive staff wrap their arms around all
          of the information they needed, to document their existing enterprise,
          and prepare the lengthy RFP process that was to follow, according to
          the mandate of their Board of Directors.
          <br />
          <br />
          Tony spent considerable time interviewing the executive staff about
          their various systems and processes. Ultimately, a high-level
          reference architecture was sketched out as a guide to ensure their
          future solution addressed everything it should, and nothing slipped
          through the cracks.
          <br />
          <br />
          Many months later Tony was contacted and invited to participate in the
          RFP, but unfortunately, the timing conflicted with his current
          project.
        </>
      )
    },
    {
      name: "Deep Mile",
      path: "deep-mile",
      featuredImage: featuredDM,
      tech: ["AWS", "PostgreSQL", "MongoDB", "Python"],
      Content: () => (
        <>
          Tony was hired through Gortek as a subcontractor to help the{" "}
          <a
            className="text-primary underline"
            href="https://deepmile.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            DeepMile
          </a>{" "}
          team of data scientists as a technical resource.
          <br />
          <br />
          <a
            href="https://deepmile.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            DeepMile
          </a>{" "}
          specializes in data analysis but needed a hand fixing a broken
          instance of PostgreSQL, tightening security, and coaching the team on
          AWS best practices.
          <br />
          <br />
          Python was used to automate a number of tasks including complex
          MongoDB queries, massaging the data, and loading it into their main
          analytics PostgreSQL database that the analysts ran a number of
          intelligence tools against.
          <br />
          <br />
          It was just a short-term engagement, but the opportunity to work
          hand-in-hand with an elite team of data scientists was a rewarding
          experience.
        </>
      )
    }
  ];

  return (
    <Layout>
      <Location>
        {({ location }) => (
          <SEO
            title="Projects Info"
            pathname={location.pathname}
            keywords={[
              "gortek",
              "app",
              "app design and development",
              "water utility app",
              "boise works",
              "pc richards",
              "e-commerce",
              "deep mile",
              "data processing",
              "etl",
              "postgres",
              "mongodb",
              "data science"
            ]}
          />
        )}
      </Location>

      <MarTech />

      <section>
        <h1 className="text-primary sm:text-6xl text-4xl">Here's a sampling of our work</h1>
      </section>

      {projects.map(({ path, name, featuredImage, tech, Content }, index) => (
        <section
          key={index}
          id={path}
          className={index === 0 ? "null" : "pt-24"}
        >
          <Img
            className="w-full max-w-lg sm:float-left sm:mr-4 sm:my-0 float-none mt-5 mx-auto"
            alt={name}
            fluid={featuredImage.childImageSharp.fluid}
            draggable={false}
          />
          <h2 className="mt-2 text-3xl">{name}</h2>
          <div className="mb-4">
            {tech.map(item => (
              <img
                key={item}
                className="p-2 inline-block h-10"
                alt={item}
                title={item}
                src={require(`../../images/${techImg[item]}`)}
              />
            ))}
          </div>
          <div>
            <Content />
          </div>
        </section>
      ))}
      <div className="mb-16" />
    </Layout>
  );
}

export default Projects;

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;

export const query = graphql`
  query {
    featuredTui: file(relativePath: { eq: "tui-in-the-field.jpg" }) {
      ...fluidImage
    }
    featuredBWDC: file(relativePath: { eq: "featured-bwdc.jpg" }) {
      ...fluidImage
    }
    featuredPCR: file(relativePath: { eq: "pcrichards.png" }) {
      ...fluidImage
    }
    featuredNCCCO: file(relativePath: { eq: "featured-nccco.png" }) {
      ...fluidImage
    }
    featuredDM: file(relativePath: { eq: "featured-deep-mile.png" }) {
      ...fluidImage
    }
  }
`
